<template>
  <div class="row mx-3 mb-1">
    <div class="col-4 p-0 mb-2 pr-3 prod-image">
      <img :src="optimizeImage(shown_image)" :alt="element.name + ' image'" class="d-inline"
        style="max-height: 105px; overflow: hidden;" />
    </div>
    <div class="col-8 p-0">
      <div class="prod-right-info d-inline-block my-auto">
        <h6 class="baddon-title t-left t-secondary m-sm">{{ element.name }}</h6>
        <p :class="{
          'baddon-price t-secondary t-bold lg m': true,
        }">
          <span v-if="has_multiple_variants && variant_picked == null" :class="{
            't-secondary baddon-from': true,
          }">
            From
          </span>
          £{{
            parseFloat(variant_picked === null ? product_lowest_price_variant.price : variant_picked_full.price).toFixed(2)
          }}
        </p>
      </div>
      <div class="mb-2">
        <Dropdown v-if="show_grind" :elementId="'baddon-option-grind'" :options="grind_sizes"
          :selected="grind_size !== null ? grind_size.id : ''" @select="setGrindSize" :center="true"
          selectclass="baddon-grind" :has_placeholder="true">
          <template #text="{ selectedElement, isOpen }">
            <div class="row mx-0">
              <div class="col-10 px-0">
                <span class="baddon-grind-txt t-secondary">
                  {{ selectedElement ? selectedElement.text : '' }}
                </span>
              </div>
              <div class="col-2 px-0 d-flex align-items-center justify-content-center">
                <img src="/assets/icons/secondary/chevron_down.svg" :class="{
                  'baddon-arrow': true,
                }" :style="{
  transform: isOpen
    ? 'scaleY(-1)'
    : 'scaleY(1)'
}" />
              </div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="mb-2">
        <Dropdown v-if="has_multiple_variants" :elementId="'baddon-option-variants'" :options="element_variant_options"
          :selected="variant_picked !== null ? variant_picked.id : ''" @select="setVariant" :center="true"
          selectclass="baddon-grind" :has_placeholder="true">
          <template #text="{ selectedElement, isOpen }">
            <div class="row mx-0">
              <div class="col-10 px-0">
                <span class="baddon-grind-txt t-secondary">
                  {{ selectedElement ? selectedElement.text : '' }}
                </span>
              </div>
              <div class="col-2 px-0 d-flex align-items-center justify-content-center">
                <img src="/assets/icons/secondary/chevron_down.svg" :class="{
                  'baddon-arrow': true,
                }" :style="{
  transform: isOpen
    ? 'scaleY(-1)'
    : 'scaleY(1)'
}" />
              </div>
            </div>
          </template>
        </Dropdown>
      </div>

      <button type="button"
        :class="{ 'btn btn-primary-secondary-txt full-width sm btn-sm mb-3': true, disabled: is_incomplete }"
        @click="addToBasket">
        Add to basket
      </button>
    </div>
  </div>
</template>

<script setup>
import { toRefs, ref, defineAsyncComponent, onMounted, computed } from 'vue'
import { useCloudinaryHelpers } from '../../../utils/composables/cloudinaryHelpers'
import { useProductInfoHelpers } from '../../composables/productInfoHelpers'
const Dropdown = defineAsyncComponent(() => import('../../../utils/dropdown/BaddonDropdown.vue'))

// Props (defineProps)
const props = defineProps({
  element: {
    type: Object,
    required: true,
  },
  addcart: {
    type: Function,
    required: true,
  },
})
// Emits (defineEmits)
// Reactive variables (inc composables)
const { element } = toRefs(props)
const { optimizeImage } = useCloudinaryHelpers()
const { product_lowest_price_variant, show_grind, has_multiple_variants, available_variants, product_is_pod } = useProductInfoHelpers(element.value,false)

const grind_size = ref(null)
const variant_picked = ref(null)
const variant_picked_full = ref(null)

const grind_sizes = [
  {
    id: 7,
    text: 'Choose brew method',
    value: 'wholebean',
  },
  {
    id: 0,
    text: 'Wholebean',
    value: 'wholebean',
  },
  {
    id: 1,
    text: 'Espresso machines',
    value: 'fine',
  },
  {
    id: 2,
    text: 'AeroPress',
    value: 'medium_fine',
  },
  {
    id: 3,
    text: 'Stove-top',
    value: 'medium_fine',
  },
  {
    id: 4,
    text: 'V60 (drip)',
    value: 'medium',
  },
  {
    id: 5,
    text: 'Chemex',
    value: 'medium',
  },
  {
    id: 6,
    text: 'Cafetière',
    value: 'coarse',
  },
]
// Computed
const is_incomplete = computed(() => {
  return (show_grind.value && grind_size.value === null) || (has_multiple_variants.value && variant_picked.value === null)
})

const shown_image = computed(() => {
  if(!has_multiple_variants.value) return element.value.primary_image
  return variant_picked.value === null ? element.value.primary_image : variant_picked_full.value.primary_image
})

const element_variant_options = computed(() => {

  const new_options = available_variants.value.map((variant, index) => {
    return {
      id: index,
      text: variant.amount_display + ' (£' + parseFloat(variant.price).toFixed(2) + ')',
      value: variant.sku,
    }
  })
  if (has_multiple_variants.value) {
    new_options.unshift({
      id: 100,
      text: 'Choose your ' + (product_is_pod.value ? 'pod' : 'bag') + ' size',
      value: '',
    })
  }
  return new_options
})
// Methods

function setGrindSize(grind) {
  grind_size.value = grind;
}

function setVariant(variant) {
  variant_picked.value = variant;
  variant_picked_full.value = available_variants.value.find(v => v.sku === variant.value)
}

function addToBasket() {
  const element_to_add = { ...element.value }
  // ITS A BAG
  if (show_grind.value) {
    element_to_add['grind_size'] = grind_size.value.value
  }
  //  has multiple variant
  if (has_multiple_variants.value) {
    element_to_add['variant'] = variant_picked_full.value
  }
  else element_to_add['variant'] = element.value.variants[0]

  props.addcart(element_to_add)
  afterATC()
}

function afterATC() {
  var basketDiv = document.getElementById('basket-cnt');
  basketDiv.scrollTop = 0;
  //RESET ON ATC
  grind_size.value = null;
  variant_picked.value = null;
  variant_picked_full.value = null;
}
// Watchers
// Lifecycle Hooks
onMounted(() => {
  if (!has_multiple_variants.value) {
    variant_picked.value = element_variant_options.value[0]
    variant_picked_full.value = available_variants.value[0]
  }
})
// Expose (defineExpose)
</script>

<style lang="scss">
.baddon-price,
.baddon-title,
.baddon-grind-txt {
  font-family: Apercu;
  font-style: normal;
  font-weight: 700;
}

.baddon-grind-txt {
  @media (max-width: 992px) {
    font-weight: 300;
  }
}

.baddon-title {
  font-size: 14px;
  line-height: 18px;
}

.baddon-price {
  font-size: 20px;
  line-height: normal;
}

.baddon-from {
  font-family: Apercu;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
  vertical-align: top;
}

.baddon-grind {
  border: 1px solid #D9D9D9;
  background: #FFF;
  padding: 10px;
}

.baddon-grind-txt {
  font-size: 12px;
  line-height: normal;
}

.baddon-arrow {
  width: 7px;
  height: 7px;
}
</style>
