import { ref, onBeforeMount, onBeforeUnmount } from 'vue'
// by convention, composable function names start with 'use'
export function useMobileHelpers() {
	// state encapsulated and managed by the composable
	const isMobile = ref(false)
	const isMobileSm = ref(false)
	const isLargeScreen = ref(false)
	const isTablet = ref(false)

	function onResize() {
		isMobile.value = window.innerWidth < 992
		isMobileSm.value = window.innerWidth < 577
		isLargeScreen.value = window.innerWidth > 1900
		isTablet.value = window.innerWidth >= 768 && window.innerWidth <= 1199
	}
	onBeforeMount(() => {
		onResize()
		window.addEventListener('resize', onResize, { passive: true })
	})
	onBeforeUnmount(() => {
		if (typeof window !== 'undefined') {
			window.removeEventListener('resize', onResize, { passive: true })
		}
	})
	// expose managed state as return value
	return { isMobile, isMobileSm, isLargeScreen, isTablet }
}
