import { ref } from 'vue'
// by convention, composable function names start with 'use'
export function useShopSort() {
	// state encapsulated and managed by the composable
	const sortOption = ref(5)
	const sortOptions = ref([
		{ id: 1, text: 'Newest' },
		{ id: 2, text: 'Best seller' },
		{ id: 3, text: 'Price: Low to High' },
		{ id: 4, text: 'Price: High to Low' },
		{ id: 5, text: 'Featured' },
	])

	function sortProducts(a, b) {
		if (a.promoted && !b.promoted) {
			return -1
		} else if (!a.promoted && b.promoted) {
			return 1
		}
		return (a.position || 100000) - (b.position || 100000)
	}

	function showVariant(variant) {
    return variant.available && variant.visible
	}

	function stockReducer(a, b) {
		var stockA = a?.stock
		var stockB = b?.stock
		return stockA === null || stockB === null ? null : ( stockA >= stockB ? stockA : stockB)
	}

	function priceReducerOffer(a, b) {
		var priceA = a.on_offer ? a.offer.offer_price : a.price
		var priceB = b.on_offer ? b.offer.offer_price : b.price
		return parseFloat(priceA) <= parseFloat(priceB) ? a : b
	}

  function priceReducerVoucher(a, b) {
		var priceA = a.on_voucher ? a.voucher.price : a.price
		var priceB = b.on_voucher ? b.voucher.price : b.price
		return parseFloat(priceA) <= parseFloat(priceB) ? a : b
	}

	function sortByPrice(direction) {
		const dir = direction === 'low' ? 1 : -1
		return function (a, b) {
      var priceA = undefined
      var priceB = undefined
      if(a.store_discount === 'voucher' && b.store_discount === 'voucher' ){
        var lowest_variant_a = a.variants.filter(showVariant).reduce(priceReducerVoucher,{})
        var lowest_variant_b = b.variants.filter(showVariant).reduce(priceReducerVoucher,{})

        priceA = lowest_variant_a.on_voucher ? lowest_variant_a.voucher.price : lowest_variant_a.price
        priceB = lowest_variant_b.on_voucher ? lowest_variant_b.voucher.price : lowest_variant_b.price
      }else{
        var lowest_variant_a = a.variants.filter(showVariant).reduce(priceReducerOffer,{})
        var lowest_variant_b = b.variants.filter(showVariant).reduce(priceReducerOffer,{})

        priceA = lowest_variant_a.on_offer ? lowest_variant_a.offer.offer_price : lowest_variant_a.price
        priceB = lowest_variant_b.on_offer ? lowest_variant_b.offer.offer_price : lowest_variant_b.price
      }
      console.log(priceA)
			return (parseFloat(priceA) - parseFloat(priceB)) * dir
		}
	}

	function featuredSort(a, b) {
    var stock_a = a.variants.filter(showVariant).reduce(stockReducer,{})
    var stock_b = b.variants.filter(showVariant).reduce(stockReducer,{})
		if (
			stock_a !== null &&
			stock_a !== undefined &&
			stock_a === 0 &&
			(stock_b === null || stock_b > 0 || stock_b === undefined)
		) {
			return 1
		} else if (
			(stock_a === null || stock_a > 0 || stock_a === undefined) &&
			stock_b !== null &&
			stock_b !== undefined &&
			stock_b === 0
		) {
			return -1
		}
		return sortProducts(a, b)
	}

	function sortPlansDropdown(a, b) {
		var weigths = { select_plan: 1, microlot_plan: 2, house_plan: 3 }
		var a_weigth = weigths[a.plan]
		var b_weigth = weigths[b.plan]
		return a_weigth - b_weigth
	}
	function sortShop(products) {
		switch (sortOptions.value[sortOption.value - 1].text) {
			case 'Newest':
				return [
					...products.filter((prod) => prod.ribbon === 'new').sort(featuredSort),
					...products.filter((prod) => prod.ribbon !== 'new').sort(featuredSort),
				]
			case 'Best seller':
				return [
					...products.filter((prod) => prod.ribbon === 'promoted').sort(featuredSort),
					...products.filter((prod) => prod.ribbon !== 'promoted').sort(featuredSort),
				]
			case 'Price: Low to High':
				return products.sort(sortByPrice('low'))
			case 'Price: High to Low':
				return products.sort(sortByPrice('high'))
			default:
				return products.sort(featuredSort)
		}
	}

	function selectSortOption(option) {
		sortOption.value = option
	}
	// expose managed state as return value
	return {
		sortShop,
		sortPlansDropdown,
		featuredSort,
		sortByPrice,
		selectSortOption,
		sortOptions,
		sortOptionSelected: sortOption,
	}
}
